<template>
    <div>
        <header-menu ref="HeaderMenu"></header-menu>
        <div class="view-content">
            <div class="banner">
                <div class="desc__box text_left">
                    <h1 class="banner_title b_b_space font-size_60">
                        销讯通•行为管理
                    </h1>
                    <p class="banner_desc b_b_space font-size_20">
                        S2P销讯通营销行为管理系统是面向未来的、基于”代表备案制度”体系下的医药营销智能管理系统，为医药企业提供主数据、辖区、行为等管理服务，为医药营销团队提供便捷实用性工具。
                    </p>
                </div>
            </div>
            <div class="l_r_space t__space">
                <h2 class="nav__title">核心功能</h2>
                <div class="tab_Jurisdiction">
                    <a-tabs
                        default-active-key="1"
                        :tabBarStyle="{
                            borderBottom: 'none',
                        }"
                    >
                        <a-tab-pane
                            key="1"
                            tab="客户管理"
                            :tabBarStyle="{
                                borderBottom: 'none',
                            }"
                        >
                            <a-row
                                :gutter="120"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                    :xs="{ span: 24 }"
                                    :sm="{ span: 24 }"
                                    :md="{ span: 11, offset: 1 }"
                                    :lg="{ span: 11, offset: 1 }"
                                    :xl="{ span: 11, offset: 1 }"
                                    :xxl="{ span: 11, offset: 1 }"
                                >
                                    <img
                                        style="max-width: 700px"
                                        src="@/assets/img/png_new/p2ps_1.png"
                                        alt="客户管理"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">客户管理</p>
                                    <p class="Jurisdiction_desc">
                                        涵盖6万+等级与社区医院数据库，49万+的药店基础信息，百万级医生学术档案，以及超过200万篇医生对应学术文献信息。系统支持主数据清洗服务，为药企建立产品、终端机构、目标HCP、医药代表关联库。
                                    </p>
                                    <br />
                                    <p class="Jurisdiction_desc">
                                        赋予医药代表对目标客户添加修改的自主权，使医药代表的日常拜访工作更加灵活。
                                    </p>
                                    <br />
                                    <p class="Jurisdiction_desc">
                                        客户与终端可自动分级，各等级的客户管理，可以根据不同场景要求进行单独设置，包括同岗同产品不同区域不同诉求，最终为药企形成符合实际业务场景的过程管理报表。
                                    </p>
                                </a-col>
                            </a-row>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="拜访管理">
                            <a-row
                                :gutter="120"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                    :xs="{ span: 24 }"
                                    :sm="{ span: 24 }"
                                    :md="{ span: 9, offset: 3 }"
                                    :lg="{ span: 9, offset: 3 }"
                                    :xl="{ span: 9, offset: 3 }"
                                    :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                        src="@/assets/img/png_new/p2ps_2.png"
                                        alt="拜访管理"
                                        style="max-width: 485px"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">拜访管理</p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            电子围栏式签到，不支持人员轨迹跟踪，仅支持终端多点定位，任一坐标在有效范围内即可一键签到。
                                        </p>
                                        <p>
                                            系统支持代表发起的协访申请和主管发起的协访计划，并支持在线确认功能。针对不合理的协访可在线评论并再次修改
                                        </p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                        <a-tab-pane key="3" tab="销量预估">
                            <a-row
                                :gutter="120"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                    :xs="{ span: 24 }"
                                    :sm="{ span: 24 }"
                                    :md="{ span: 9, offset: 3 }"
                                    :lg="{ span: 9, offset: 3 }"
                                    :xl="{ span: 9, offset: 3 }"
                                    :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                        src="@/assets/img/png_new/p2ps_3.png"
                                        alt="销量预估"
                                        style="max-width: 570px"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">销量预估</p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            根据各月的指标填写每月的预估销量(可细化到终端和客户)，管理层及时了解销量进度，及时预测销量风险，调整销售计划。同时可以标签化客户，从而与客户考核关联。
                                        </p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                        <a-tab-pane key="4" tab="会议管理">
                            <a-row
                                :gutter="120"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                    :xs="{ span: 24 }"
                                    :sm="{ span: 24 }"
                                    :md="{ span: 9, offset: 3 }"
                                    :lg="{ span: 9, offset: 3 }"
                                    :xl="{ span: 9, offset: 3 }"
                                    :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                        src="@/assets/img/png_new/p2ps_4.png"
                                        style="max-width: 600px"
                                        alt="会议管理"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">会议管理</p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            支持会议计划申请、会议费用记录以及相关材料上传。会议活动可直接与主数据中的客户数据关联。
                                        </p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                        <a-tab-pane key="5" tab="可视化报表">
                            <a-row
                                :gutter="120"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                    :xs="{ span: 24 }"
                                    :sm="{ span: 24 }"
                                    :md="{ span: 9, offset: 3 }"
                                    :lg="{ span: 9, offset: 3 }"
                                    :xl="{ span: 9, offset: 3 }"
                                    :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                        src="@/assets/img/png_new/p2ps_5.png"
                                        style="max-width: 540px"
                                        alt="可视化报表"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">可视化报表</p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            多维度进行CRM管理的分析报表，同时具备营销管理及医药大健康领域的行业沉淀。支持医药代表实时了解每月的工作情况，使代表能够及时调整工作安排，同时支持定制化报表
                                        </p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                    </a-tabs>
                </div>
            </div>
            <div class="l_r_space t__space" style="background-color: #eee">
                <h2 class="nav__title">为什么选择正也科技</h2>
                <h3 class="nav__desc" style="padding: 0.1rem 0 0.4rem 0">
                    行业有深度 数据有态度
                </h3>
                <div>
                    <a-row :gutter="20" type="flex">
                        <a-col
                            style="padding: 0 0.1rem 20px 0.1rem"
                            :xs="24"
                            :sm="12"
                            :md="12"
                            :lg="8"
                            :xl="8"
                            :xxl="8"
                            v-for="(item, index) in softWareList"
                            :key="index"
                        >
                            <a-card>
                                <a-row style="align-items: center" type="flex">
                                    <a-col
                                        :xs="24"
                                        :sm="10"
                                        :md="10"
                                        :lg="10"
                                        :xl="10"
                                        :xxl="10"
                                        ><img
                                            :src="item.img_url"
                                            :alt="item.title"
                                            style="width: 100%; max-width: 80px"
                                    /></a-col>
                                    <a-col
                                        :xs="24"
                                        :sm="14"
                                        :md="14"
                                        :lg="14"
                                        :xl="14"
                                        :xxl="14"
                                        ><div style="min-height: 1.1rem">
                                            <h3
                                                class="soft_title text_left b_b_space font-size_26"
                                            >
                                                {{ item.title }}
                                            </h3>
                                            <p
                                                class="soft_desc text_left b_b_space font-size_16"
                                            >
                                                {{ item.desc }}
                                            </p>
                                        </div></a-col
                                    >
                                </a-row>
                            </a-card>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Footer from '@/components/Footer'
import HeaderMenu from '@/components/HeaderMenu'
import soft_1 from '@/assets/img/png/soft_1.png'
import soft_2 from '@/assets/img/png/soft_2.png'
import soft_3 from '@/assets/img/png/soft_3.png'
import soft_4 from '@/assets/img/png/soft_4.png'
import soft_5 from '@/assets/img/png/soft_5.png'
import soft_6 from '@/assets/img/png/soft_6.png'
export default {
    name: 'p2ps',
    beforeCreate() {},
    components: { Footer, HeaderMenu },
    data() {
        return {
            softWareList: [
                {
                    title: '行业深度',
                    desc:
                        '正也科技核心成员为医药营销管理咨询专家和多年医药行业软件开发实施经验。',
                    img_url: soft_1,
                },
                {
                    title: '资源丰富',
                    desc:
                        '公司拥有海量行业主数据供客户选择，以及丰富的关键管理识别经验',
                    img_url: soft_2,
                },
                {
                    title: '产品规范',
                    desc:
                        'S2P支持全面数据颗粒化、追求灵活配置，应对企业未来管理需求',
                    img_url: soft_3,
                },
                {
                    title: '敏捷开发',
                    desc:
                        '基于主数据平台深度颗粒化，不断快速迭代，满足企业的个性化需求',
                    img_url: soft_4,
                },
                {
                    title: '致力创新',
                    desc:
                        'S2P专注于研究“营销方法论”、“营销新算法”，“让数据有态度”',
                    img_url: soft_6,
                },
                // {
                //   title: "致力于创新",
                //   desc: "公司专注于研究如何使用信息化的方法，以“数”助销",
                //   img_url: soft_6,
                // },
            ],
        }
    },
    created() {},
    methods: {
        handleSubmit() {},
    },
}
</script>
<style scoped lang="scss">
@import '@/assets/style/color.scss';
.soft_title {
    padding: 0.15rem 0 0.1rem 0;
}
.soft_desc {
    color: $color-fixGray;
}
.banner {
    width: 100%;
    position: relative;
    background: url('~@/assets/img/png/p2ps_banner.png');
    background-size: 100% 100%;
    height: 8rem;
    overflow: hidden;
    .desc__box {
        position: absolute;
        top: 1.5rem;
        left: 13.5%;
        .banner_desc {
            width: 8.1rem;
            padding-top: 0.3rem;
        }
    }
}
.row_view-box {
    align-items: center;
    padding: 0rem 0 0.8rem 0;
}
</style>
